<template>
  <div
    class="hover-card"
    @click="active = !active"
    v-click-outside="close"
    :class="{ 'hover-card--active': active }"
  >
    <img
      :src="require(`@/assets/images/fifth/${cardData.img}`)"
      :alt="`фото ${cardData.title}`"
      :class="active ? 'hover-card__img--active' : 'hover-card__img'"
    />
    <div :class="['hover-card-right', { 'hover-card-right--active': active }]">
      <div class="hover-card-right-inner">
        <span class="hover-card-right__title">{{ cardData.title }}</span>
        <span class="hover-card-right__subtitle">
          {{ cardData.subtitle }}
        </span>
      </div>
      <span v-if="active" class="hover-card-right__description">
        {{ cardData.description }}
      </span>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'HoverCard',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDesktop: true,
      active: false,
    }
  },
  created() {
    window.addEventListener('resize', this.mq)
    this.mq
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.mq)
  },
  computed: {
    check() {
      return window.matchMedia('(max-width: 1025px)').matches
    },
  },
  methods: {
    mq() {
      this.isDesktop = window.matchMedia('(max-width: 1025px)').matches
    },
    close() {
      this.active = false
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-card {
  z-index: 200;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  background: $white;
  border-radius: 0px 10px 10px 0px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  @include flex-row();

  @include mobile-landscape {
    flex-direction: column;
  }

  &--active {
    z-index: 1000 !important;
    max-width: 456px;

    @include mobile-landscape {
      max-width: 200px;
    }
    @include mobile {
      position: absolute;
      top: 5% !important;
      border-radius: 10px;
      left: calc(50% - 250px) !important;
    }
  }

  &__img {
    width: 70px;
    height: 70px;
    background-size: cover;
    animation: activeAnim linear 0.6s;

    @keyframes activeAnim {
      0% {
        width: 200px;
        height: 200px;
      }
      100% {
        width: 70px;
        height: 70px;
      }
    }

    @include mobile-landscape {
      width: 56px;
      height: 56px;
      border: 3px solid $white;
    }

    &--active {
      width: 200px;
      height: 200px;
      animation: activeCard linear 0.5s;

      @keyframes activeCard {
        0% {
          width: 56px;
          height: 56px;
        }
        100% {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  &-right {
    padding: 10px 0;
    gap: 4px;
    @include flex-column();

    @include mobile-landscape {
      display: none;
    }

    &--active {
      @include mobile-landscape {
        padding: 10px 14px 20px;
        display: block !important;
      }
    }

    &-inner {
      padding: 0 30px 0 14px;
      @include flex-column();

      @include mobile-landscape {
        padding: 0;
      }
    }

    &__title {
      @include font-styles(18px, 25px, 700, $c-main-green);

      @include mobile-landscape {
        padding-bottom: 4px;
        @include font-styles(14px, 20px, 700, $c-main-green);
      }
    }
    &__subtitle,
    &__description {
      @include font-styles(15px, 20px, 400, $c-gray);

      @include mobile-landscape {
        @include font-styles(12px, 16px, 400, $c-gray);
      }
    }

    &__description {
      display: inline-block;
      padding: 20px 15px 0 14px;
      animation: active linear 0.8s;

      @include mobile-landscape {
        padding: 20px 0 0 0;
      }

      @keyframes active {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
</style>
