<template>
  <div class="fifth">
    <div class="fifth-inner container">
      <h2 class="fifth__title wow" data-wow-offset="100">
        Архитектура проекта
      </h2>
      <section class="fifth-left wow" data-wow-offset="100">
        <p class="fifth-left__text--strong">
          Во всех своих объектах наша компания использует лишь материалы
          высокого качества, которые уже зарекомендовали себя на рынке
        </p>
        <p class="fifth-left__text">
          Помимо качества используемых материалов мы учли внешнее оформление
          кондиционеров, которые часто портят красивый фасад дома, поэтому в
          нашем ЖК кондиционеры будут закрытыми специальными корзинами, которые
          отлично сочетаются с внешним видом дома. При этом мы никогда не
          забываем про эстетичный вид всего здания, поэтому фасад ЖК Capital
          будет отделан фиброцементной плиткой, которая идеально впишется в
          архитектуру города.
        </p>
      </section>
    </div>
    <div class="fifth-img wow">
      <img
        src="@/assets/images/fifth/fifth-main.png"
        alt="Архитектура проекта"
        class="fifth-img__img"
      />
      <HoverCard
        v-for="(card, i) in cards"
        :key="i"
        :card-data="card"
        :class="['fifth-img__card', `fifth-img__card--${i}`]"
      />
    </div>
  </div>
</template>

<script>
import HoverCard from '@/components/atoms/Cards/HoverCard/index.vue'
import { WOW } from 'wowjs'
export default {
  name: 'FifthSection',
  components: { HoverCard },
  data() {
    return {
      active: false,
      cards: [
        {
          title: 'Фиброцементная плита',
          subtitle: 'Сверхнадежная, вековая',
          description:
            'Сверхнадежная, вековая, универсальная, экологические чистая фиброцементная плита, изготавливаемая по безасбестовой технологии.',
          img: 'tile.webp',
        },
        {
          title: 'Окна ALUTECH',
          subtitle: 'Алюминиевые окна',
          description:
            'Окна обеспечивают высокий уровень комфорта, а также защиту помещений от теплопотерь и шума с улицы.',
          img: 'windows.webp',
        },
        {
          title: 'Корзины под кондиционеры',
          subtitle: 'Аккуратная, металлическая',
          description:
            'Защитит внешний блок кондиционера от перегрева, не перекрывая поступление свежего воздуха.',
          img: 'box.webp',
        },
        {
          title: 'Магазин',
          subtitle: 'Современный, универсальный',
          description: 'Самый широкий ассортимент товаров рядом с вами',
          img: 'shop.webp',
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
}
</script>

<style lang="scss" scoped>
.fifth {
  width: 100%;
  position: relative;
  height: fit-content;

  @include mobile {
    overflow: hidden;
  }

  &-img {
    position: relative;
    z-index: 1;
    margin-top: -240px;
    width: 100%;
    height: 950px;
    animation: marginTop 1s;

    @keyframes marginTop {
      from {
        margin-top: -140px;
      }
      to {
        margin-top: -240px;
      }
    }

    @include laptop {
      margin-top: -200px;
    }

    @include tablet-portrait {
      margin-top: -100px;
      height: 700px;

      @keyframes marginTop {
        from {
          margin-top: 0;
        }
        to {
          margin-top: -100px;
        }
      }
    }
    @include mobile-landscape {
      margin-top: 0;
      height: 450px;
    }
    @include mobile {
      position: relative;
      min-width: 680px;
      height: 402px;
      overflow: hidden;

      @keyframes marginTop {
        from {
          margin-top: 100px;
        }
        to {
          margin-top: 0;
        }
      }
    }

    @include mobile-small {
      min-width: 550px;
    }

    &__img {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__card {
      z-index: 200;
      position: absolute;

      &--0 {
        top: 30%;
        left: 51%;

        @include mobile-landscape {
          top: 8%;
          left: 32%;
        }
      }
      &--1 {
        top: 42%;
        left: 22%;

        @include mobile-landscape {
          top: 36%;
          left: 11%;
        }
      }
      &--2 {
        top: 51%;
        left: 62%;

        @include tablet-portrait {
          left: 52%;
          top: 61%;
        }

        @include mobile-landscape {
          top: 55%;
          left: 40%;
        }
      }
      &--3 {
        top: 90%;
        left: 30%;

        @include mobile-landscape {
          top: 80%;
          left: 15%;
        }
      }
    }
  }

  &-inner {
    justify-content: space-between;
    @include flex-row();

    @include mobile-landscape {
      gap: 30px;
      flex-direction: column;
    }
    @include mobile {
      gap: 16px;
    }
  }
  &__title {
    max-width: 620px;
    animation: manifestation1 0.8s ease-in;
    @include font-styles(48px, 58px, 700, $c-dark, 0, $font-third);

    @include mobile {
      padding-bottom: 0;
      @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
    }
  }

  &-left {
    padding-top: 24px;
    animation: manifestation1 1s ease-in;
    align-items: flex-start;
    max-width: 620px;
    @include flex-column();

    @include tablet-portrait {
      padding-left: 15px;
    }

    @include mobile-landscape {
      padding-top: 0;
      padding-left: 10px;
    }

    @include mobile {
      padding: 0;
      max-width: none;
      width: 100%;
    }
    &__text {
      padding-bottom: 22px;
      @include font-styles(18px, 24px, 400, $c-gray, 0, $font-second);

      @include mobile-landscape {
        padding-bottom: 14px;
      }

      @include mobile {
        text-align: left;
        max-width: 97%;
        @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
      }
    }
    &__text--strong {
      max-width: 580px;
      padding-bottom: 30px;
      @include font-styles(22px, 30px, 700, $c-dark);

      @include mobile-landscape {
        padding-bottom: 14px;
      }

      @include mobile {
        text-align: left;
        @include font-styles(16px, 20px, 700, $c-dark);
      }
    }
  }
}
</style>
